import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
// import Introduction from "../components/Service/Introduction"
import ServiceContent from "../components/Service/ServiceContent"
// import ServicesList from "../components/Service/ServicesList"
import Breadcrumb from "../components/Global/Breadcrumb"



function ServiceTemplate(props) {
    let data = props.data;
    console.log(props)

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpService.nodes[0].serviceCategories.nodes[0].breadcrumbServicesAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}/${data.allWpService.nodes[0].serviceCategories.nodes[0].breadcrumbServicesAcf.breadcrumbSlug}/`
        })
        bCrumb.push({
            text: data.allWpService.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpService.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>

                        <section>
                            <Breadcrumb list={getBreadcrumb()} h1={data.allWpService.nodes[0].breadcrumbAcf.breadcrumbName} />
                        </section>


                    <section className="container flex flex-wrap m-auto justify-center md:my-10">
                        <ServiceContent content={data.allWpService.nodes[0].content} featured={data.allWpService.nodes[0].servicesAcf.smallIcon} relatedPosts={data.allWpService.nodes[0].relatedPosts.relatedPosts} language={props.pageContext.language} />
                    </section>

                </div>
            </Layout>
        </div>

    )
}

export default ServiceTemplate

// export const pageQuery = graphql`query GET_SERVICE($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
//   wpgraphql {
//     service(id: $id) {
//       seo {
//         canonical
//         title
//         metaDesc
//         opengraphDescription
//         opengraphTitle
//         opengraphImage {
//           sourceUrl
//         }
//       }
//       breadcrumbAcf {
//         breadcrumbName
//         image {
//           sourceUrl
//           altText
//           localFile {
//             childImageSharp {
//               gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
//             }
//           }
//         }
//       }
//       serviceCategories {
//         nodes {
//           breadcrumbServicesAcf {
//             breadcrumbName
//             breadcrumbSlug
//           }
//         }
      
//     }
//       title
//       content
//       uri
//       servicesAcf {
//         smallIcon {
//           sourceUrl
//           altText
//           localFile {
//             childImageSharp {
//               gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
//             }
//           }
//         }
//       }

//       relatedPosts {
//         relatedPosts {
//           ... on WpPost {
//             uri
//             slug
//             title
//             date
//             author {
//               node {
//                 firstName
//               }
//             }
//             featuredImage {
//               node {
//                 sourceUrl
//                 altText
//                 localFile {
//                   childImageSharp {
//                     gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
//                     }
//                 }
//               }
//             }
//           }
//         }
//       }

//     }

//     template(id: $themeTemplateUri, idType: SLUG) {
//       contactInformationTemplate {
//         telephoneNumber
//         supportEmail
//         hoursLabel
//         hoursTitle
//         form {
//           title
//           subTitle
//           fullName
//           email {
//             label
//             errorMessage
//           }
//           subject
//           message {
//             label
//             errorMessage
//           }
//           telephone {
//             label
//             errorMessage
//           }
//           sendButton
//         }
//         responseMessages {
//           success
//           failed
//         }
//       }
//     }

//     posts(
//       first: 2
//       where: {language: $language, orderby: {field: DATE, order: DESC}}
//     ) {
//       ...RecentPostsFragment
//     }
//   }
// }
// `

export const pageQuery = graphql`query GET_SERVICE($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpService(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    breadcrumbAcf {
         breadcrumbName
        
       }
       serviceCategories {
          nodes {
            breadcrumbServicesAcf {
              breadcrumbName
              breadcrumbSlug
            }
          }
      }
      title
      content
      uri
      servicesAcf {
        smallIcon {
          sourceUrl
          altText
          
        }
      }

      relatedPosts {
        relatedPosts {
          ... on WpPost {
            uri
            slug
            title
            date
            author {
              node {
                firstName
              }
            }
            featuredImage {
              node {
                sourceUrl
                altText
                
              }
            }
          }
        }
      }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
